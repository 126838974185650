<template>
<!--  <div class="home">-->
  <div class="center" style="width: 300px;height:300px;margin:auto;margin-top: 100px;background-color: #b4bccc;border-radius: 16px;">
      <div style="width: 260px">
          <van-form @submit.prevent="login">
              <van-field style="border-top-left-radius: 10px;border-top-right-radius: 10px"
                      v-model="username"
                      name="用户名"
                      label="用户名"
                      placeholder="用户名"
                     @keydown.enter="login"
              />
              <van-field style="border-bottom-left-radius: 10px;border-bottom-right-radius: 10px"
                      v-model="password"
                      type="password"
                      name="密码"
                      label="密码"
                      placeholder="密码"
                     @keydown.enter="login"
              />
              <div class="center" style="margin-top: 10px">
                  <el-row>
                      <el-col :span="12"><van-button type="default" color="#b4bccc" @click="zhuce">注册</van-button></el-col>
                      <el-col :span="12"><van-button type="default" color="#b4bccc" style="width: 100px" @click="backPwd">忘记密码</van-button></el-col>
                  </el-row>
              </div>
              <div style="margin: 16px;">
                  <van-button round block type="info" native-type="button" @click="login">登录</van-button>
              </div>
          </van-form>
      </div>
  </div>
</template>

<script>
// @ is an alias to /src
import HelloWorld from '@/components/HelloWorld.vue'
import { loginId } from "@/api/login"
import {Dialog, Notify} from "vant";
export default {
  name: 'HomeView',
  components: {
    HelloWorld
  },
    data(){
      return {
          username: '',
          password: '',
          form:{
              name
          }
      }
    },
    created() {

    },
    methods: {
        zhuce(){
            //跳转注册界面
            this.$router.push('zhuce')
        },
        login(){
            let msg = {
                username:this.username,
                jsonName:this.username,
                password:this.password
            }
            loginId(msg).then(res =>{
                if(res.data.code === 200){
                    //存储token
                    localStorage.setItem('username',this.username)
                    sessionStorage.setItem('username',this.username)
                    if(res.data.token){
                        localStorage.setItem('uuid',res.data.token)
                    }
                    //存储定时
                    this.setFutureTime(this.username,7*24*60) //7天
                    //登录成功,跳转
                    this.$router.push('MainView')
                    Notify({ type: 'success', message: res.data.message });
                }else if(res.data.code === 444){
                    Notify({ type: 'danger', message: res.data.message });
                }else{
                    Notify({ type: 'danger', message: '登录失败' });
                }
            }).catch(error => {
                console.error('Login request failed:', error);
                Notify({ type: 'danger', message: '登录请求失败' });
            })
        },
        backPwd(){
            Dialog.alert({
                message: '未开发,找黑果/凌舍去要密码',
            }).then(() => {
                // on close
            });
        },
        // 用户登录时设置未来时间
        setFutureTime(username, durationInMinutes) {
            const currentTime = new Date().getTime();
            //durationInMinutes 分
            const futureTime = currentTime + durationInMinutes * 60 * 1000; // 将时间转换为毫秒
            localStorage.setItem(username, futureTime);
        },
    }
}
</script>

<style>

.center{
    display:flex;
    justify-content: center;
    align-items:center;
}
</style>