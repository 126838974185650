import axios from 'axios'
import errorCode from '@/utils/errorCode'
import {
    getToken
} from '@/utils/auth'

import {
    Dialog,
    Notify
} from 'vant';
import router from "@/router";

axios.defaults.headers['Content-Type'] = 'application/json;charset=utf-8'

// 创建axios实例
const service = axios.create({
    // axios中请求配置有baseURL选项，表示请求URL公共部分
    baseURL: process.env.VUE_APP_BASE_API, // 本地后台接口||服务器接口
    // baseURL: "http://localhost:8081",
    // 超时
    timeout: 50000
})
// request拦截器
service.interceptors.request.use(config => {
    // 是否需要设置 token
    const isToken = (config.headers || {}).isToken === false
    config.headers['Authorization'] = 'Bearer ' + getToken()
    config.headers['uuid'] = localStorage.getItem('uuid')
    console.log(localStorage.getItem('username'))
    config.headers['jsonname'] = localStorage.getItem('username')
    console.log('请求头:', config.headers)
    // get请求映射params参数
    if (config.method === 'get' && config.params) {
        let url = config.url + '?';
        for (const propName of Object.keys(config.params)) {
            const value = config.params[propName];
            var part = encodeURIComponent(propName) + "=";
            if (value !== null && typeof (value) !== "undefined") {
                if (typeof value === 'object') {
                    for (const key of Object.keys(value)) {
                        let params = propName + '[' + key + ']';
                        var subPart = encodeURIComponent(params) + "=";
                        url += subPart + encodeURIComponent(value[key]) + "&";
                    }
                } else {
                    url += part + encodeURIComponent(value) + "&";
                }
            }
        }
        url = url.slice(0, -1);
        config.params = {};
        config.url = url;
    }
    return config
}, error => {
    console.log(error)
    Promise.reject(error)
})

// 响应拦截器
service.interceptors.response.use(res => {

    // 检查 res.data 是否存在
    if (!res.data) {
        return Promise.reject(new Error('Response data is undefined'));
    }
    // 未设置状态码则默认成功状态
    const code = res.data.code || 200; // 假设你的 API 返回的状态码在 res.data.code 中
    const msg = errorCode[code] || res.data.message || errorCode['default'];
    // 二进制数据则直接返回
    if (res.config.responseType === 'blob' || res.config.responseType === 'arraybuffer') {
        return res;
    }
    // 根据状态码处理响应
    if (code === 401) {
        Notify({ type: 'warning', message: '无效的会话，或者会话已过期，请重新登录。' })
        return Promise.reject('无效的会话，或者会话已过期，请重新登录。')
    } else if (code === 402 || code === 403 || code === 500) {
        Notify({ type: 'danger', message: msg })
        return Promise.reject(new Error(msg))
    } else if (code === 601) {
        Notify({ type: 'warning', message: msg })
        return Promise.reject('error')
    } else if (code === 4444) {
        Notify({ type: 'warning', message: msg })
        //删除网页存储
        const token = localStorage.getItem('username');
        localStorage.removeItem(token);
        localStorage.removeItem('username');
        localStorage.removeItem('uuid');
        sessionStorage.removeItem('username');
        //跳转登录界面
        router.push('/login'); // 跳转到登录页面
        return Promise.reject('error')
    }
    // 处理成功情况，返回数据
    return res;
}, error => {
    console.log('err' + error);
    let { message } = error;

    // 处理网络错误和超时等情况
    if (message === "Network Error") {
        message = "服务器正在重启或是崩溃.请稍等服务器正在重启";
    } else if (message.includes("timeout")) {
        message = "连接超时,请刷新或稍等";
    } else if (message.substr(message.length - 3) === 502) {
        message = "服务器正在重启或是崩溃.请稍等服务器正在重启"; // 例如502异常
    } else if (message.includes("Request failed with status code")) {
        message = "系统接口" + message.substr(message.length - 3) + "异常"; // 例如502异常
    }

    // 弹出报错信息
    Notify({
        message: message,
        type: 'danger',
        duration: 50 * 1000,
    });

    return Promise.reject(error);
});



// 使用a标签下载
export function fileDown(blobUrl, filename) {
    const a = document.createElement("a");
    if (!a.click) {
        throw new Error('DownloadManager: "a.click()" is not supported.');
    }
    a.href = blobUrl;
    a.target = "_parent";
    if ("download" in a) {
        a.download = filename;
    }
    (document.body || document.documentElement).append(a);
    a.click();
    a.remove();
}

export default service

