import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

//移动端适配
import 'amfe-flexible';

import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';

Vue.use(ElementUI);

// 引入vantUI
import Vant from 'vant';
import 'vant/lib/index.css';
Vue.use(Vant);


// 自定义基础样式库
import './styles/index.css'

Vue.config.productionTip = false


// 创建一个方法来加载配置文件
function loadConfig() {
  return fetch('/config.json')
      .then(response => {
        if (!response.ok) {
          throw new Error('Failed to load config');
        }
        return response.json();
      })
      .then(config => {
        // 将配置存储在 Vue 的全局属性中，方便后续使用
        Vue.prototype.$config = config;
      })
      .catch(error => {
        console.error('Error loading config:', error);
      });
}

// 在加载配置后再初始化 Vue 实例
loadConfig().then(() => {
  new Vue({
    router,
    store,
    render: h => h(App)
  }).$mount('#app')
});

// new Vue({
//   router,
//   store,
//   render: h => h(App)
// }).$mount('#app')
