import axios from "axios";
import request from '@/utils/request'
// 查询常用意见列表

export function loginId(data) {
    return request({
        method: 'post',
        url: "/Login", // 替换为实际的目标地址
        data: data
    })
}

export function zhuCeId(data) {
    return request({
        method: 'post',
        url: "/ZhuCe", // 替换为实际的目标地址
        data: data
    })
}

export function getNameFlag(data) {
    return request({
        method: 'post',
        url: "/getNameFlag", // 替换为实际的目标地址
        data: data
    })
}
