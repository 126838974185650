import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'login',
    component: () => import(/* webpackChunkName: "about" */ '../views/HomeView.vue')
  },
  {
    path: '/login',
    name: 'login',
    component: () => import(/* webpackChunkName: "about" */ '../views/HomeView.vue')
  },
  {
    path: '/zhuce',
    name: 'zhuce',
    component: () => import(/* webpackChunkName: "about" */ '../views/ZhuCe.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/MainView',
    name: 'MainView',
    component: () => import(/* webpackChunkName: "about" */ '../views/MainView.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/ConfigView',
    name: 'ConfigView',
    component: () => import(/* webpackChunkName: "about" */ '../views/ConfigView.vue'),
    meta: { requiresAuth: true }
  }
]

const router = new VueRouter({
  routes
})

const whiteList = ['/login','/zhuce','/']

// 添加全局导航守卫
router.beforeEach((to, from, next) => {
  const token = localStorage.getItem('username');
  sessionStorage.setItem('username',token);
  const currentTime = new Date().getTime();
  const storedTime = localStorage.getItem(token);

  // 检查路由是否需要验证
  // if (token) {
  if (storedTime && currentTime < storedTime){
    if(to.path === '/login' || to.path === '/'){
      next({
        path: '/MainView',
      });
      sessionStorage.setItem('username',token)
    }else{
      next();
    }
  } else {
    if (whiteList.indexOf(to.path) !== -1) {
      // 在免登录白名单，直接进入
      next()
    } else {
      // 否则重定向到登录页面
      if (to.query.redirect) {
        next({ path: '/login' });
      } else {
        console.log('Redirecting to login with redirect:', to.fullPath);
        next({ path: '/login', query: { redirect: to.fullPath } });
      }
    }
  }
});


export default router
